import React from 'react';

function Otter(){
    return(

<div>asdf</div>

//   <header class="container xlarge padding-24">
//     <a href="#" class="left button white">art of the day</a>
//     <a href="#about" class="right button white">about</a>
//   </header>

    // <div class="img center" style="height:100%">
        // <img src="https://pablo-prod-images-lo-res.s3.eu-west-2.amazonaws.com/image.png" style="width:40%" class="padding-64"></img>
        // <h2 class="padding-8" id="countdown"></h2>
    // </div>
  
//   <div style="height:250px"></div>

    )
};

export default Otter;